import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import HorizontalCalendar from 'common/components/HorizontalCalendar/HorizontalCalendar'
import { DATE_FORMAT } from 'common/constants/dateFormatConstants'
import dayjs from 'dayjs'
import { PathUtils } from 'routes/routes.utils'
import { useFetchScheduleMonthQuery } from 'features/Schedule/api/schedule.api'
import { DateService } from 'common/services/dateService'
import { Select } from 'common/components/Select/Select'

import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { Col, Row } from 'antd'

import styles from './schedule.module.scss'
import { AddWalkInPatientButton } from 'features/Home/Book/Booking/Schedule/PatientSchedule/AddWalkInPatientButton/AddWalkInPatientButton'
import {
  ALLOW_RESCHEDULE_CURRENT_DAY_PAST_EVENTS,
  INFO_CONSTANTS,
  PATIENT_STATUSES,
} from 'features/Home/constants/infoConstants'
import { SELECT_CONSTANTS } from 'common/constants/selectConstants'
import { PATIENT_ACTIVITY_INTERVAL } from 'features/Home/constants/infoActivityTime'
import { ScheduleCalendar } from 'features/Home/Book/Booking/Schedule/PatientSchedule/ScheduleCalendar/ScheduleCalendar'
import type { IPatientSchedule } from 'features/Home/interfaces/IInfoSchedule'
import { DocumentResourceHeader } from 'features/Home/Book/Booking/Schedule/PatientSchedule/DocumentResourceHeader/DocumentResourceHeader'
import type { IPatientRoom } from 'features/Home/interfaces/IInfoPatient'
import { useTimezone } from 'common/hooks/useTimezone'
import Show from 'common/components/Show2/Show'
import { PatientScheduleSkeleton } from 'features/Home/Book/Booking/Schedule/PatientSchedule/PatientScheduleSkeleton/PatientScheduleSkeleton'
import { ManageAppointment } from './components/ManageAppointment/ManageAppointment'
import { useScheduleData } from './hooks/useScheduleData'
import { useScheduleActions } from './hooks/useScheduleActions'
import { useMultipleChannels } from 'features/Schedule/pusher/MultiplePusherProvider'
import { useSchedulePusher } from 'features/Schedule/pusher/useSchedulePusher'
import { EmptyRoomsList } from 'features/Home/Book/Booking/Schedule/PatientSchedule/EmptyRoomsList/EmptyRoomsList'
import { Button } from 'common/components/Button/Button'

const { preserveDateInTimezone } = DateService

dayjs.extend(isSameOrAfter)
dayjs.extend(customParseFormat)

const SchedulePage = () => {
  const navigate = useNavigate()
  const { siteId, day } = useParams()
  const [defaultActiveDate, setDefaultActiveDate] = useState<string | null>(null)
  const [calendarMonthDate, setCalendarMonthDate] = useState<string | null>(null)
  const { timeZone, isFetchingSites } = useTimezone()
  const {
    data: scheduleMonth,
    isLoading: isLoadingMonth,
    isFetching: isFetchingScheduleMonth,
  } = useFetchScheduleMonthQuery({
    siteId,
    ...prepareScheduleMonth(),
  })

  const {
    isFetchingDay,
    rooms,
    schedules,
    patients,
    consultation_staffs,
    languages,
    timeInterval,
    disabledIntervals,
    eventsToSendNotification,
    bookingIds,
    doctorBufferTime,
    roomStatuses,
  } = useScheduleData(defaultActiveDate)

  const { setBookingIds } = useMultipleChannels()
  useSchedulePusher(defaultActiveDate)

  const {
    updateAppointment,
    updateRoomStatus,
    isLoading: isUpdating,
    isNotifying,
    isUpdatingRoomStatus,
  } = useScheduleActions()

  const isLoading = isLoadingMonth || !defaultActiveDate || isFetchingDay || isFetchingSites

  const hasNoBooking = !isLoading && (!timeInterval?.max || !timeInterval?.min)

  const today = useMemo(() => {
    return preserveDateInTimezone(dayjs().startOf('day'), timeZone).format(
      DATE_FORMAT.PATIENT_TRACKER_DATE,
    )
  }, [timeZone])

  const isInPast = useMemo(() => {
    if (!defaultActiveDate || !timeZone) return true
    const selectedDate = preserveDateInTimezone(defaultActiveDate, timeZone).startOf('day')
    return selectedDate.isBefore(today, 'day')
  }, [today, timeZone, defaultActiveDate])

  const [editedPatient, setEditedPatient] = useState<IPatientSchedule | null>(null)
  const [addPatient, setAddPatient] = useState<boolean>(false)
  const [prePopulateSchedule, setPrePopulateSchedule] = useState<number | null>(
    PATIENT_ACTIVITY_INTERVAL[0].code as number,
  )

  const [customAppointmentData, setCustomAppointmentData] = useState<{
    appointment: Date[]
    room: string
  } | null>(null)

  const allDays = useMemo(() => {
    if (!scheduleMonth) return []
    return Object.keys(scheduleMonth)
  }, [JSON.stringify(scheduleMonth)])

  const availableDays = useMemo(() => {
    return allDays.filter((key) => scheduleMonth[key].active)
  }, [JSON.stringify(allDays)])

  const isDisabledWalkinPatientButton = useMemo(() => {
    if (!defaultActiveDate || !timeZone || isFetchingDay) return true
    return isInPast
  }, [isFetchingDay, isInPast])

  function checkInitialDate() {
    if (!timeZone) return
    if (!day || !dayjs(day).isValid()) {
      const currentDate = preserveDateInTimezone(dayjs(), timeZone).format(
        DATE_FORMAT.PATIENT_TRACKER_DATE,
      )
      onChangeLeaseDate(currentDate)
    } else {
      setDefaultActiveDate(
        preserveDateInTimezone(day, timeZone).format(DATE_FORMAT.PATIENT_TRACKER_DATE),
      )
    }
  }

  function prepareScheduleMonth() {
    const date = preserveDateInTimezone(calendarMonthDate ?? dayjs(), timeZone)
    const startDate = date.startOf('month').add(-1, 'week').format(DATE_FORMAT.PATIENT_TRACKER_DATE)
    const endDate = date.endOf('month').add(1, 'week').format(DATE_FORMAT.PATIENT_TRACKER_DATE)
    return { startDate, endDate }
  }

  const onChangeLeaseDate = useCallback(
    (date: string): void => {
      const formattedDate = preserveDateInTimezone(date, timeZone).format(
        DATE_FORMAT.PATIENT_TRACKER_DATE,
      )
      setDefaultActiveDate(formattedDate)
      navigate(PathUtils.getSchedule(siteId, formattedDate))
    },
    [siteId, timeZone],
  )

  const getWaitingRoomPatients = () => {
    try {
      const waitingRoom = schedules.find((room: IPatientSchedule) => {
        return room.room.is_waiting_room
      })
      if (waitingRoom) {
        const waitingRoomCode = waitingRoom.room.value
        const checkedPatientsInWaitingRoom = schedules.filter((patient: IPatientSchedule) => {
          return (
            patient.room.value === waitingRoomCode &&
            patient.status.code === PATIENT_STATUSES.CHECKED_IN_ON_SITE
          )
        })
        return checkedPatientsInWaitingRoom.length
      }
      return null
    } catch (error) {
      return null
    }
  }

  const updateStatus = (value: number, room: IPatientRoom) => {
    updateRoomStatus(
      room.id,
      {
        status_type_code: value,
        lease_id: room?.booking_id,
      },
      defaultActiveDate,
    )
  }

  const handleCloseModal = () => {
    setAddPatient(false)
    setEditedPatient(null)
    setCustomAppointmentData(null)
  }

  const createScheduleWithCustomAppointment = (
    start: Date,
    end: Date,
    resourceId: string,
  ): void => {
    setCustomAppointmentData({ appointment: [start, end], room: resourceId })
    setAddPatient(true)
  }

  const roomsHeader = useMemo(() => {
    if (!defaultActiveDate || !timeZone) return undefined
    const isSameDate = preserveDateInTimezone(defaultActiveDate, timeZone).isSame(today, 'day')
    return rooms.map((room: IPatientRoom) => {
      return {
        resourceId: `${room.label}/${room.value}`,
        resourceTemplate: (
          <DocumentResourceHeader
            doctorsBufferTime={doctorBufferTime}
            room={room}
            hasRoomStatus={!room.is_waiting_room && !!room.add_patient && isSameDate}
            statusList={roomStatuses}
            updateStatus={updateStatus}
            timeZone={timeZone}
            key={room.id}
            disabledIntervals={disabledIntervals}
            dayInterval={timeInterval}
            isUpdatingRoomStatus={isUpdatingRoomStatus}
            {...(room.is_waiting_room && { patientsCount: getWaitingRoomPatients() })}
          />
        ),
      }
    })
  }, [
    JSON.stringify(rooms),
    JSON.stringify(disabledIntervals),
    JSON.stringify(roomStatuses),
    JSON.stringify(doctorBufferTime),
    JSON.stringify(timeInterval),
    timeZone,
    isUpdatingRoomStatus,
    defaultActiveDate,
  ])

  const disabledInputsManageAppointment = useMemo(() => {
    if (isInPast) return true
    if (!editedPatient) return false
    return !ALLOW_RESCHEDULE_CURRENT_DAY_PAST_EVENTS.includes(editedPatient?.status.code)
  }, [editedPatient, isInPast])

  const handleUpdateEvents = useCallback(
    async (adjustedData: any, event: any) => {
      return await updateAppointment(adjustedData, defaultActiveDate, event.id)
    },
    [defaultActiveDate],
  )

  useEffect(() => {
    checkInitialDate()
  }, [day, timeZone, today, JSON.stringify(availableDays)])
  useEffect(() => {
    if (bookingIds.length) {
      setBookingIds(bookingIds)
    }
  }, [JSON.stringify(bookingIds)])

  return (
    <Row gutter={[24, 24]}>
      <Col xs={24}>
        <HorizontalCalendar
          handleClickDisabledDate={onChangeLeaseDate}
          isFetching={isFetchingScheduleMonth}
          isLoading={isLoadingMonth || isFetchingSites || !defaultActiveDate}
          defaultActiveDate={defaultActiveDate}
          availableDays={availableDays}
          onDateChange={onChangeLeaseDate}
          onChangeMonth={(date) => {
            setCalendarMonthDate(date.format(DATE_FORMAT.PATIENT_TRACKER_DATE))
          }}
          minDate={dayjs(allDays.at(0))}
          maxDate={dayjs(allDays.at(-1))}
          timeZone={timeZone}
          forceEnableFutureDate
          forceEnablePastDate
        />
      </Col>
      {!isLoading && !hasNoBooking && (
        <Col xs={24}>
          <div className={styles.actionContainer}>
            <div className={styles.headContentSelect}>
              <span className={styles.headInfo}>{INFO_CONSTANTS.DEFAULT_APPT}:</span>
              <div className={styles.headSelect}>
                <Select
                  onSelect={setPrePopulateSchedule}
                  popupClassName='table-select-content'
                  proportion={SELECT_CONSTANTS.SMALL}
                  listOptions={PATIENT_ACTIVITY_INTERVAL}
                  value={prePopulateSchedule}
                />
              </div>
            </div>

            <div className={styles.headButton}>
              <AddWalkInPatientButton
                isDisabled={isDisabledWalkinPatientButton}
                handleClick={() => setAddPatient(true)}
                label={INFO_CONSTANTS.ADD_WALK_IN_PATIENT}
              />
            </div>

            <div className={styles.emptySpace} />
          </div>
        </Col>
      )}
      <Col xs={24}>
        <Show>
          <Show.When isVisible={isLoading}>
            <PatientScheduleSkeleton />
          </Show.When>
          <Show.When isVisible={hasNoBooking}>
            <EmptyRoomsList
              title={INFO_CONSTANTS.NO_ACTIVE_SCHEDULE_TODAY}
              content={
                <Button onClick={() => navigate(PathUtils.getBook(siteId))}>Book now →</Button>
              }
            />
          </Show.When>
          <Show.Else>
            <ScheduleCalendar
              setEditedPatient={setEditedPatient}
              createCustomAppointment={createScheduleWithCustomAppointment}
              handleUpdateEvents={handleUpdateEvents}
              resources={roomsHeader}
              prepopulateTimeOnClick={prePopulateSchedule}
              dayInterval={timeInterval}
              eventsToSendNotification={eventsToSendNotification}
              isSendingNotifications={isNotifying}
              isUpdating={isUpdating}
              disabledIntervals={disabledIntervals}
              timeZone={timeZone}
              rooms={rooms}
              schedules={schedules}
            />
          </Show.Else>
        </Show>
      </Col>
      <ManageAppointment
        title={
          !!editedPatient ? INFO_CONSTANTS.EDIT_WALK_IN_PATIENT : INFO_CONSTANTS.ADD_WALK_IN_PATIENT
        }
        date={defaultActiveDate}
        open={addPatient || !!editedPatient}
        isEditing={!!editedPatient}
        editedPatientData={editedPatient}
        setOpen={handleCloseModal}
        patients={patients}
        consultation_staffs={consultation_staffs}
        dayInterval={timeInterval}
        languages={languages}
        prePopulateSchedule={prePopulateSchedule}
        disabledEverything={disabledInputsManageAppointment}
        customAppointment={customAppointmentData}
        rooms={rooms}
        schedules={schedules}
        timeInterval={timeInterval}
        disabledIntervals={disabledIntervals}
        timeZone={timeZone}
        isSendingNotifications={false}
      />
    </Row>
  )
}

export default SchedulePage
