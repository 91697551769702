import type { FC } from 'react'
import { memo, useEffect, useMemo, useState } from 'react'
import { useInterval, useToggle } from 'react-use'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { Tooltip } from 'antd'
import patientIcon from 'features/Home/assets/patient.svg'

import { SuffixSelect } from 'common/components/Select'
import { Show } from 'common/components/Show/Show'
import { BUTTON_MODIFIER } from 'common/constants/buttonConstants'
import {
  CHECK_FOR_DISABLED_CHANGE_ROOM_STATUS_INTERVAL,
  INFO_CONSTANTS,
  ROOM_STATUS,
} from 'features/Home/constants/infoConstants'
import { InfoService } from 'features/Home/services/infoService'

import styles from './documentResourceHeader.module.scss'
import type { DocumentResourceHeaderProps } from './documentResourceHeader.types'
import { AnnounceIconButton } from 'features/Home/Book/Booking/Schedule/Announce/announce'
import { AnnounceModal } from 'features/Home/Book/Booking/Schedule/Announce/announce-modal'
import { useAuth } from 'app/providers'
import { useParams } from 'react-router-dom'
import { Icon } from '@iconify/react'
import { Link } from 'react-router-dom'
import { PathUtils } from 'routes/routes.utils'

dayjs.extend(utc)
dayjs.extend(timezone)

export const DocumentResourceHeader: FC<DocumentResourceHeaderProps> = memo(
  ({
    room,
    hasRoomStatus = true,
    doctorsBufferTime,
    statusList = [],
    updateStatus = () => {},
    timeZone,
    patientsCount,
    isUpdatingRoomStatus,
    disabledIntervals,
    dayInterval,
  }) => {
    const { day, siteId } = useParams()
    const [openAnnounceModal, toggleAnnounceModal] = useToggle(false)
    const { isStaff } = useAuth()
    const [dropdownDisabled, setDropDownDisabled] = useState(false)
    const getIsDisabledDropdown = () => {
      return InfoService.checkIfCurrentDateOverlapDisabledIntervals(
        disabledIntervals,
        room,
        dayInterval,
        timeZone,
        doctorsBufferTime,
      )
    }

    useInterval(() => {
      setDropDownDisabled(getIsDisabledDropdown())
    }, CHECK_FOR_DISABLED_CHANGE_ROOM_STATUS_INTERVAL)

    useEffect(() => {
      setDropDownDisabled(getIsDisabledDropdown())
    }, [room])

    const patientNumber = (() => {
      if (patientsCount >= 0) {
        return patientsCount
      }

      return room.max_patients || 0
    })()

    const hasAnnounce = (() => {
      if (!isStaff) return false
      if (room.is_waiting_room || !room.add_patient) return false

      if (!dayjs(day).isSame(dayjs(), 'day')) return false

      const currentTime = dayjs().tz(timeZone).format('HH:mm:ss')

      return room.slots?.some((slot) => {
        return currentTime >= slot.start_time && currentTime <= slot.end_time
      })
    })()

    const link = useMemo(() => {
      if (!room.booking_id) return null

      return room?.is_promotion
        ? PathUtils.getPromotionDetails(siteId, room.booking_id)
        : PathUtils.getBookingDetails(siteId, room.booking_id)
    }, [room?.is_promotion, room.booking_id, siteId])

    return (
      <>
        <AnnounceModal open={openAnnounceModal} toggleOpen={toggleAnnounceModal} roomId={room.id} />
        <div className={styles.parent}>
          <div className={styles.parentHeader}>
            <h2 className={styles.parentRoomName}>{room.label}</h2>
            <div className={styles.parentHeaderRight}>
              {link && (
                <Link to={link}>
                  <Icon
                    style={{ color: 'var(--tertiary-100)' }}
                    width={20}
                    height={20}
                    fontWeight={600}
                    icon='solar:link-bold'
                  />
                </Link>
              )}
              {hasAnnounce && <AnnounceIconButton onClick={toggleAnnounceModal} />}
              <Show when={!!room?.add_patient}>
                <Tooltip
                  title={
                    room.is_waiting_room
                      ? INFO_CONSTANTS.CURRENT_PATIENTS_IN_WAITING_ROOM
                      : INFO_CONSTANTS.SAME_TIME_ALLOWED_PATIENTS
                  }
                  placement='topLeft'>
                  <div className={styles.parentPatientNumberContainer}>
                    <img
                      className={styles.parentPatientNumberIcon}
                      src={patientIcon}
                      alt='patient allowed slots'
                    />
                    <span className={styles.parentPatientNumber}>{patientNumber}</span>
                  </div>
                </Tooltip>
              </Show>
            </div>
          </div>
          <Show when={hasRoomStatus}>
            <div className={styles.parentRoomSelect}>
              <SuffixSelect
                isLoading={isUpdatingRoomStatus}
                listOptions={statusList}
                title='Room status'
                value={dropdownDisabled ? 'N/A' : room?.status?.code}
                onChange={(value) => updateStatus(value, room)}
                icon={InfoService.getRoomStatusIcon(
                  dropdownDisabled ? ROOM_STATUS.DISABLE : room?.status?.code || ROOM_STATUS.BUSY,
                )}
                modifier={BUTTON_MODIFIER.SECONDARY}
                disabled={dropdownDisabled}
              />
            </div>
          </Show>
        </div>
      </>
    )
  },
)
